import i18next from 'i18next'
import _ from 'lodash'
import { MoneyDto } from '../services/APIs/InternalAPI/internal-api.contracts'

export function MoneyIsValid(money: MoneyDto) {
  if (!money) return false

  return money.value > 0
}

export function MoneyString(
  money: MoneyDto,
  maximumFractionDigits?: number,
  replaceZeroWithString?: string,
  useCurrencyCode?: boolean
) {
  if (!money) return null

  if (money.value == null) {
    return '-'
  }

  if (
    (money.value === 0 || isNaN(money.value)) &&
    replaceZeroWithString !== undefined
  ) {
    return replaceZeroWithString
  }

  if (useCurrencyCode) {
    return formatCurrencyWithCurrencyCode(money, maximumFractionDigits)
  }

  return formatCurrency(
    i18next.language,
    money.currency,
    money.value,
    maximumFractionDigits
  )
}

export function formatCurrencyWithCurrencyCode(
  money: MoneyDto,
  maximumFractionDigits?: number
) {
  if (!money) return null

  const formatedValue = new Intl.NumberFormat(i18next.language, {
    style: 'decimal',
    maximumFractionDigits: maximumFractionDigits ?? 2,
  }).format(money.value)

  return `${formatedValue} ${money.currency}`
}

export function formatCurrency(
  language: string,
  currency: string,
  value: number,
  maximumFractionDigits?: number
) {
  return new Intl.NumberFormat(language, {
    style: 'currency',
    currency: currency || 'EUR',
    maximumFractionDigits: maximumFractionDigits ?? 2,
  }).format(value)
}

export function SumMoney(prices: MoneyDto[]): MoneyDto {
  const sumValue = _.sumBy(prices, (x) => x?.value || 0)

  return {
    ...prices[0],
    value: sumValue,
  }
}

export function MultiplyMoney(money: MoneyDto, multiplicant: number) {
  return money && { ...money, value: money.value * multiplicant || 0 }
}
