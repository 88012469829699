import { BaseController } from 'controllers/BaseController'
import { isEqual } from 'lodash'
import React, { useCallback } from 'react'

type ApiErrors = {
  validation: unknown
} & Error

export function useAppController<T extends BaseController<unknown>>(
  controllerFactory: () => T,
  options?: {
    /**
     * the loading, error, validation, and progress states will not be updated. May be useful for components that do not need to track the loading state, or are rerendered frequently or costly.
     */
    disableSubscribeToUpdates?: boolean
    /**
     * just the loading state will not be updated. May be useful for components that do not need to track the loading state, or are rerendered frequently or costly.
     */
    disableTrackLoading?: boolean
  }
) {
  const [loading, setLoading] = React.useState<{ [k: string]: boolean }>({})
  const [error, setError] = React.useState<{ [k: string]: ApiErrors }>({})
  const [validation, setValidation] = React.useState<{
    [validationKey: string]: string
  }>({})
  const [progress, setProgress] = React.useState<{
    [operationKey: string]: number
  }>({})

  const controller = React.useRef(controllerFactory())

  React.useEffect(() => {
    if (!options?.disableSubscribeToUpdates) {
      controller.current.subscribeToUpdates?.((status) => {
        if (!options?.disableTrackLoading) {
          setLoading((curr) =>
            isEqual(curr, status.loading) ? curr : status.loading
          )
        }

        setError((curr) => (isEqual(curr, status.error) ? curr : status.error))

        setValidation((curr) =>
          isEqual(curr, status.validation) ? curr : status.validation
        )

        setProgress((curr) =>
          isEqual(curr, status.progress) ? curr : status.progress
        )
      })
    }
  }, [options?.disableSubscribeToUpdates, options?.disableTrackLoading])

  const resetRequestsState = useCallback(() => {
    try {
      console.log('here')
      setLoading({})
      setError({})
      setValidation({})
      setProgress({})

      controller.current.CancelRequests()
    } catch (err) {
      console.info('requests states canceled', err)
    }
  }, [])

  return {
    controller: controller.current as T,
    loading,
    error,
    validation,
    progress,
    resetRequestsState,
    setLoading,
  }
}
