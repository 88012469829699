import { CaseReducer, PayloadAction } from '@reduxjs/toolkit'
import { MergeWithCustomizer, mergeWith } from 'lodash'
import { BoMItemRow } from 'model/Project/BoMItemRow'
import { BomItemPointer } from 'model/Project/BomItemPointer'
import { ProjectModel } from 'model/ProjectModel'
import { RecursivePartial } from 'utils/RecursivePartial'
import { ProjectState } from '../../../../store/Project/ProjectTypes'
import { bomItemSelector } from '../selectors/bomItemSelector'

const keepLatestSourceArrayValue: MergeWithCustomizer = (
  value,
  srcValue,
  key
) => {
  if (Array.isArray(value) || key === 'tokens') {
    return srcValue
  }
}

const fullReplacer: MergeWithCustomizer = (value, srcValue) => {
  return srcValue
}

export const bomItemReplacer: CaseReducer<
  ProjectState,
  PayloadAction<{
    bomItemPointer: BomItemPointer
    properties: Partial<BoMItemRow>
    replacementBehaviour?:
      | 'keepLatestSourceIfArrayOrToken'
      | 'default'
      | 'fullReplace'
  }>
> = (state, action): ProjectState => {
  if (!action.payload) {
    return state
  }

  const {
    replacementBehaviour = 'keepLatestSourceIfArrayOrToken',
    ...rowProperties
  } = action.payload

  let replacementStrategy = null

  switch (replacementBehaviour) {
    case 'keepLatestSourceIfArrayOrToken':
      replacementStrategy = keepLatestSourceArrayValue
      break
    case 'fullReplace':
      replacementStrategy = fullReplacer
      break
    default:
      replacementStrategy = null
  }

  const bomItem = bomItemSelector(
    { project: state },
    action.payload.bomItemPointer
  )

  if (!bomItem) return state

  //   if (bomItem?.type === BomItemType.assemblyType) {
  //     mergeWith(
  //       bomItem,
  //  { ...rowProperties.properties },
  //       replacementStrategy
  //     )
  //   } else {
  mergeWith(bomItem, rowProperties.properties, replacementStrategy)
  // }

  return state
}

export const activeProjectReplacer: CaseReducer<
  ProjectState,
  PayloadAction<Partial<ProjectModel>>
> = (state, action) => {
  if (!action.payload) {
    return state
  }

  mergeWith(state.activeProject, action.payload, keepLatestSourceArrayValue)

  return state
}

export const projectStateReplacer: CaseReducer<
  ProjectState,
  PayloadAction<RecursivePartial<ProjectState>>
> = (state, action) => {
  if (!action.payload) {
    return state
  }

  mergeWith(state, action.payload, keepLatestSourceArrayValue)

  return state
}
